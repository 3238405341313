<template>
    <div class="flex flex-row space-x-2">
        <div class="pl-2 text-xs font-bold space-y-2">
            <p>Lägg till noter i resultaträkning</p>
            <el-cascader
                :options="localResultNoteNumbersList"
                :props="props"
                collapse-tags
                clearable
                :disabled="inputDisabled"
                :style="{ width: '220px' }"
                v-model="localSelectedResultNoteNumbers"
                @change="changeResultList"
            />
        </div>
        <div class="pl-2 text-xs font-bold space-y-2">
            <p>Lägg till noter i balansräkning</p>
            <el-cascader
                :options="localBalanceNoteNumbersList"
                :props="props"
                collapse-tags
                clearable
                :disabled="inputDisabled"
                :style="{ width: '220px' }"
                v-model="localSelectedBalanceNoteNumbers"
                @change="changeBalanceList"
            />
        </div>
    </div>
</template>

<script>
import { Cascader } from "element-ui";

export default {
    components: {
        "el-cascader": Cascader,
    },
    props: {
        inputDisabled: {
            type: Boolean,
            default: true,
        },
        notesNumber: {
            type: Number,
            default: 0,
        },
        resultNoteNumbersList: {
            type: Array,
            default: () => [],
        },
        selectedResultNoteNumbers: {
            type: Array,
            default: () => [],
        },
        balanceNoteNumbersList: {
            type: Array,
            default: () => [],
        },
        selectedBalanceNoteNumbers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            props: { multiple: true },
        };
    },
    methods: {
        changeResultList(selectedValues) {
            this.selectRow(selectedValues, this.localResultNoteNumbersList);
            const selectedValuesIntList = selectedValues.flat();
            this.unSelectChosenRow(selectedValuesIntList, this.localResultNoteNumbersList);
            this.unSelectAllRows(selectedValuesIntList, this.localResultNoteNumbersList);
            this.$emit("update:resultNoteNumbersList", this.localResultNoteNumbersList);
        },
        changeBalanceList(selectedValues) {
            this.selectRow(selectedValues, this.localBalanceNoteNumbersList);
            const selectedValuesIntList = selectedValues.flat();
            this.unSelectChosenRow(selectedValuesIntList, this.localBalanceNoteNumbersList);
            this.unSelectAllRows(selectedValuesIntList, this.localBalanceNoteNumbersList);
        },
        selectRow(selectedValues, localNoteNumberList) {
            selectedValues.forEach(value => {
                const selected = localNoteNumberList.find(item => item.value === value[0]);
                if (selected && selected.notesNumber !== this.localNum) {
                    selected.notesNumber = this.localNum;
                }
            });
        },
        unSelectChosenRow(selectedList, localNoteNumberList) {
            const unSelected = localNoteNumberList.find(item => item.notesNumber === this.localNum && !selectedList.includes(item.value));
            if (unSelected && !selectedList.includes(unSelected.value)) {
                unSelected.notesNumber = 0;
            }
        },
        unSelectAllRows(selectedList, localNoteNumberList) {
            if (selectedList.length === 0) {
                localNoteNumberList.forEach(item => {
                    if (item.notesNumber === this.localNum) {
                        item.notesNumber = 0;
                    }
                });
            }
        },
    },
    computed: {
        localNum: {
            get() {
                return this.notesNumber;
            },
            set(value) {
                this.$emit("update:notesNumber", value);
            },
        },
        localResultNoteNumbersList: {
            get() {
                return this.resultNoteNumbersList;
            },
            set(value) {
                this.$emit("update:resultNoteNumbersList", value);
            },
        },
        localSelectedResultNoteNumbers: {
            get() {
                return this.selectedResultNoteNumbers;
            },
            set(value) {
                this.$emit("update:selectedResultNoteNumbers", value);
            },
        },
        localBalanceNoteNumbersList: {
            get() {
                return this.balanceNoteNumbersList;
            },
            set(value) {
                this.$emit("update:balanceNoteNumbersList", value);
            },
        },
        localSelectedBalanceNoteNumbers: {
            get() {
                return this.selectedBalanceNoteNumbers;
            },
            set(value) {
                this.$emit("update:selectedBalanceNoteNumbers", value);
            },
        },
    },
};
</script>
